import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowsH,
  faCaretRight,
  faCircle,
  faFont,
  faImage,
  faPlay,
  faPlus,
  faRss,
  faSquare,
} from '@fortawesome/free-solid-svg-icons';

import inject from '../../../../hoc/injectHoc';
import {getPlaceHolderCircleData} from './entities/placeholderCircle';
import {getPlaceHolderImageData} from './entities/placeholderImage';
import {getPlaceHolderFeedIconData} from './entities/placeholderFeedIcon';
import {getPlaceHolderLineData} from './entities/placeholderLine';
import {getPlaceHolderRectangleData} from './entities/placeholderRectangle';
import {getPlaceHolderTriangleData} from './entities/placeholderTriangle';
import {getPlaceHolderTextData} from './entities/placeholderText';
import {getPlaceHolderVideoData} from './entities/placeholderVideo';
import {ACTIVE_DELAY_MS} from '../../../../../constants/displayConstants';
import {actionAddEntityComponent} from '../../../../../display/components/action/actionAddEntityComponent';
import {actionInteractionComponent} from '../../../../../display/components/action/actionInteractionComponent';
import SelectFeedModal from '../../../../modals/selectFeed/SelectFeedModal';
import SelectMediaModal, {FILE_TYPE_MEDIA} from '../../../../modals/selectMedia/SelectMediaModal';
import {triggerResize} from '../../../../../utils/windowHelper';
import GenerateImageButton from '../../../../common/generateImageButton/GenerateImageButton';
import GenerateTextModal from '../../../../modals/generateTextModal/GenerateTextModal';
import UnsplashButton from '../../../../../components/common/unsplashButton/UnsplashButton';
import UnsplashImageModal from '../../../../../components/modals/unsplashImageModal/UnsplashImageModal';
import GenerateTextButton from '../../../../../components/common/generateTextButton/GenerateTextButton';

import './editorEntityAddButton.scss';

/**
 * The circle shape.
 * @const {string}
 */
const CIRCLE = 'circle';

/**
 * The rectangle shape.
 * @const {string}
 */
const RECTANGLE = 'rectangle';

/**
 * The triangle shape.
 * @const {string}
 */
const TRIANGLE = 'triangle';

/**
 * The line shape.
 * @const {string}
 */
const LINE = 'line';

/**
 * The EditorEntityAddButton component.
 *
 * @constructor
 */
export class EditorEntityAddButton extends React.Component {
  /**
   * Opens the real time data feed modal
   *
   * @type {(boolean)}
   */
  @observable isFeedModalOpen = false;

  /**
   * Whether or not the file choose/upload modal is open or not and the type of files.
   *
   * @type {(string|string[]|null)}
   */
  @observable isFileModalOpen = null;

  /**
   * flag if generate text modal is open
   *
   * @type {boolean}
   */
  @observable isGenerateTextModalOpen = false;

  /**
   * Whether or not the AI modal should open when the image choose/upload modal opens
   *
   * @type {boolean}
   */
  @observable forceOpenAIModal = false;

  /**
   * flag if unsplash modal is open
   *
   * @type {boolean}
   */
  @observable isUnsplashImageModalOpen = false;

  /**
   * Adds a new entity to the game.
   *
   * @param {{}} entityType
   * @param {{}|function} entityComponent
   * @param {Object?} size
   * @param {Object?} entityPosition
   */
  addNewEntity(entityType, entityComponent, size = {
    width: 200,
    height: 200,
  }, entityPosition) {
    const {
      /** @type {GameStore} */ game,
      /** @type {GameTimerStore} */ timer
    } = this.props;

    const start = 0;
    let end = (game.endTime > 0) ? game.endTime : 0;
    if (end < 0) {
      end = game.endTime;
    }

    const activeDelay = ACTIVE_DELAY_MS;
    const activeTime = (start + activeDelay > end) ? 0 : start + activeDelay;

    const resolution = game.resolution;
    const centerOfDisplay = {
      width: (resolution.width / 2),
      height: (resolution.height / 2),
    };
    const position = entityPosition || {
      y: centerOfDisplay.height - (size.height / 2),
      x: centerOfDisplay.width - (size.width / 2),
    };

    const safeComponent = (typeof entityComponent === 'function') ? entityComponent({
      resolution,
      position,
      size,
    }) : entityComponent;

    const newEntitySource = lodash.defaultsDeep({}, safeComponent, {
      type: entityType,
      startTime: start,
      endTime: end,
      activeTime,
      setup: {
        size: {
          width: `${size.width}px`,
          height: `${size.height}px`,
        },
        position,
      },
    });

    const newEntityComponent = actionAddEntityComponent(newEntitySource, true);
    const actionComponents = Object.assign(
      {},
      newEntityComponent,
      actionInteractionComponent(true, false, false)
    );

    // Now activate the new item.
    const actionParams = {
      entityId: true
    };

    game.addAction(actionParams, actionComponents);

    timer.setTime(activeTime);

    triggerResize();
  }

  /**
   * Adds a new media entity when the button is clicked.
   */
  @action onAddFeed = () => {
    this.isFeedModalOpen = true;
  };

  /**
   * Adds a new media entity when the button is clicked.
   */
  @action onAddMedia = () => {
    this.isFileModalOpen = FILE_TYPE_MEDIA;
    this.forceOpenAIModal = false;
  };

  /**
   * Adds a new feed entity when the feed is selected.
   * @param {*} newFeed
   */
  @action onFeedSelected = (newFeed) => {
    if (newFeed && newFeed.entities) {
      newFeed.entities.forEach((feedEntity) => {
        if (feedEntity.entityType === 'feed') {
          this.addNewEntity('feed', {
            name: feedEntity.type,
            feed: {
              markdown: feedEntity.markdown,
              type: feedEntity.type,
              opacity: feedEntity.opacity || 1,
            },
            position: feedEntity.position || null
          },
          feedEntity.size,
          feedEntity.position);
        } else if (feedEntity.entityType === 'feedicon') {
          this.addNewEntity('feedicon', getPlaceHolderFeedIconData(
            feedEntity.type,
            feedEntity.type,
            feedEntity.url,
            feedEntity.iconPack,
            feedEntity.iconPackUrl,
            feedEntity.opacity,
          ),
          feedEntity.size,
          feedEntity.position);
        }
      });
    }

    this.isFeedModalOpen = false;
  }

  /**
   * Triggered when the new file is selected by the modal.
   *
   * @param {{contentFiles: Array.<{id: number}>}} newContent
   */
  @action onFileSelected = (newContent) => {
    this.isFileModalOpen = null;

    if (!newContent) {
      return;
    }

    if (newContent.placeholderImage) {
      this.addNewEntity('image', getPlaceHolderImageData(
        null,
        'Placeholder',
        null
      ));
      return;
    } else if (newContent.placeholderVideo) {
      this.addNewEntity('video', getPlaceHolderVideoData(
        null,
        'Placeholder',
        null,
        0,
        'video/mp4'
      ));
      return;
    }

    if (!newContent.displayPath) {
      return;
    }

    if (newContent.duration) {
      this.addNewEntity('video', getPlaceHolderVideoData(
        newContent.id,
        newContent.filename,
        newContent.displayPath,
        newContent.duration,
        newContent.mime
      ));
    } else {
      this.addNewEntity('image', getPlaceHolderImageData(
        newContent.id,
        newContent.filename,
        newContent.displayPath
      ));
    }
  };

  /**
   * Triggered when generate image button is clicked.
   */
  @action onGenerateImageClick = () => {
    this.isFileModalOpen = FILE_TYPE_MEDIA;
    this.forceOpenAIModal = true;
  };

  /**
   * Opens generate text modal
   */
  @action onGenerateTextClick = () => {
    this.isGenerateTextModalOpen = true;
  };

  /**
   * Closes generate text modal
   */
  @action onHideGenerateTextModal = () => {
    this.isGenerateTextModalOpen = false;
  }

  /**
   * Generate text layer with selected text
   *
   * @param {string} text
   */
  @action onGenerateTextResultSelect = (text) => {
    const {game} = this.props;
    const resolution = game.resolution;

    const size = {
      width: resolution.width,
      height: resolution.height,
    };

    this.addNewEntity('text', getPlaceHolderTextData(text), size);

    this.isGenerateTextModalOpen = false;
  }

  /**
   * Triggered when unsplash image button is clicked.
   */
  @action onUnsplashClick = () => {
    this.isUnsplashImageModalOpen = true;
  };

  /**
   * Closes unsplash modal
   */
  @action onHideUnsplashModal = () => {
    this.isUnsplashImageModalOpen = false;
  }

  /**
   * Generate image layer when unsplash image selected
   *
   * @param {*} image
   */
  @action onUnsplashImageSelect = (image) => {
    const [rawImageUrl] = image.urls.raw.split('?');
    const fullUrl = `${rawImageUrl}?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=1080`;

    this.onFileSelected({
      id: image.id,
      filename: image.slug,
      displayPath: fullUrl,
    });

    this.isUnsplashImageModalOpen = false;
  }

  /**
   * Adds a new shape entity when the button is clicked.
   *
   * @param {string} shapeType
   * @returns {function}
   */
  onAddShape = (shapeType) => {
    return () => {
      switch (shapeType) {
        case CIRCLE:
          this.addNewEntity(CIRCLE, getPlaceHolderCircleData);
          break;
        case RECTANGLE:
          this.addNewEntity(RECTANGLE, getPlaceHolderRectangleData);
          break;
        case TRIANGLE:
          this.addNewEntity(TRIANGLE, getPlaceHolderTriangleData);
          break;
        case LINE:
          this.addNewEntity(LINE, getPlaceHolderLineData);
          break;
        default:
          // Do nothing.
          break;
      }
    };
  };

  /**
   * Adds a new text entity when the button is clicked.
   */
  onAddText = () => {
    const {game} = this.props;
    const resolution = game.resolution;

    const size = {
      width: resolution.width,
      height: resolution.height,
    };

    this.addNewEntity('text', getPlaceHolderTextData(), size);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {buttonText, game} = this.props;

    return (
      <span className="entity-add-button dropdown">
        <div
          id="add-entity-dropdown"
          className="clickable"
          data-toggle="dropdown"
          data-offset="-70,0"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <button
            type="button"
            className="btn btn-sm btn-outline-dark-blue"
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
          {!!buttonText && <span className="ml-2">{buttonText}</span>}
        </div>
        <div
          style={{
            width: '220px',
          }}
          className="dropdown-menu dropright"
          aria-labelledby="add-entity-dropdown"
        >
          <button type="button" className="dropdown-item" onClick={this.onAddFeed}>
            <FontAwesomeIcon icon={faRss} />
            Feed
          </button>
          <button type="button" className="dropdown-item" onClick={this.onAddMedia}>
            <FontAwesomeIcon icon={faImage} />
            Media
          </button>
          <button type="button" className="dropdown-item" onClick={this.onAddText}>
            <FontAwesomeIcon icon={faFont} />
            Text
          </button>
          <button type="button" id="add-shape-dropdown" className="dropdown-item clearfix" data-toggle="dropdown">
            <FontAwesomeIcon icon={faCircle} />
            Shape
            <FontAwesomeIcon
              className="pull-right"
              icon={faCaretRight}
            />
          </button>
          <div className="dropdown-menu" aria-labelledby="add-shape-dropdown">
            <button type="button" className="dropdown-item" onClick={this.onAddShape(CIRCLE)}>
              <FontAwesomeIcon icon={faCircle} />
              Circle
            </button>
            <button type="button" className="dropdown-item" onClick={this.onAddShape(RECTANGLE)}>
              <FontAwesomeIcon icon={faSquare} />
              Rectangle
            </button>
            <button type="button" className="dropdown-item" onClick={this.onAddShape(TRIANGLE)}>
              <FontAwesomeIcon
                rotation={270}
                icon={faPlay}
              />
              Triangle
            </button>
            <button type="button" className="dropdown-item" onClick={this.onAddShape(LINE)}>
              <FontAwesomeIcon icon={faArrowsH} />
              Line
            </button>
          </div>
          <div className="px-3 py-2">
            <span className="ai-tool-text">Explore our latest AI Tool.</span>
            <GenerateTextButton
              className="w-100"
              onClick={this.onGenerateTextClick}
            />

            <div className="mt-2">
              <GenerateImageButton
                className="w-100"
                onClick={this.onGenerateImageClick}
              />
            </div>
          </div>
          <div className="px-3 py-2">
            <span className="unsplash-tool-text">Search for stock imagery.</span>
            <UnsplashButton
              className="w-100"
              onClick={this.onUnsplashClick}
            />
          </div>
        </div>

        <SelectMediaModal
          isOpen={Boolean(this.isFileModalOpen)}
          onComplete={this.onFileSelected}
          forceOpenAIModal={this.forceOpenAIModal}
        />
        <SelectFeedModal
          isOpen={this.isFeedModalOpen}
          onComplete={this.onFeedSelected}
          resolution={game.resolution}
        />
        <GenerateTextModal
          isOpen={this.isGenerateTextModalOpen}
          onCancel={this.onHideGenerateTextModal}
          onResultClick={this.onGenerateTextResultSelect}
        />
        <UnsplashImageModal
          isOpen={this.isUnsplashImageModalOpen}
          onCancel={this.onHideUnsplashModal}
          onImageSelect={this.onUnsplashImageSelect}
        />
      </span>
    );
  }
}

EditorEntityAddButton.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,
  timer: MobxPropTypes.observableObject.isRequired,
  buttonText: PropTypes.string,
};

EditorEntityAddButton.wrappedComponent = {};
EditorEntityAddButton.wrappedComponent.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,
  timer: MobxPropTypes.observableObject.isRequired,
  buttonText: PropTypes.string,
};

export default inject(EditorEntityAddButton)(
  observer(EditorEntityAddButton)
);
